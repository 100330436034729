@media print {
    html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
}

@media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }
}

@media print {
    .btn, .navbar, .dontprint {
        opacity: 0;
    }
}

@page {
    size: auto;
    margin: 20mm;
  }

.btn-outline-green {
    border-color: #008515;
    background-color: white;
    color: #008515;
}

.btn-outline-green:hover {
    border-color: #008515;
    background-color: #008515;
    color: white;
}

.btn-outline-green.active {
    border-color: #008515;
    background-color: #008515;
    color: white;
}

.btn-green {
    background-color: #008515;
    color: white;
}

.btn-green:hover {
    background-color: #006e11;
    color: white;
}

.navbar {
    background-color: #008515;
}

.nav-link {
    color: #FFFFFF;
}

.nav-link:hover {
    color: #ebebeb;
}

.nav-link:active {
    color: #ebebeb;

}

.card-header {
    color: #008515;
}

.card.border-green {
    border-color: #008515;
}

sup {
    color: #008515;
}

p {
    text-align: justify;
    text-justify: inter-word;
}

.accordion-button {
    color:#008515;
}

.accordion-button:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23008515'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }

.accordion-button:focus {
    z-index: 3;
    border-color: #00851410;
    outline: 0;
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    background-color: #00851410;
    color: #008515;
}

.spinner-border.text-green {
    color:#008515;
}